/**
 * @file 環境依存の定数
 * @author Ozaki Takao
 */
(function () {
  'use select';

  if (typeof window.Env === 'undefined') {
    window.Env = {};
  }
  var env = {
    // システム名称
    SYSTEM_NAME: 'TOA音声配信サービス(開発)',

    // 試作環境用CognitoのUserPoolId
    COGNITO_USER_POOL_ID: 'ap-northeast-1_wmEfyheTx',

    // 試作環境用CognitoのClientId
    COGNITO_CLIENT_ID: '3iu35651862qmkdbumumi7dnin'
  };

  Object.keys(env).forEach(key => {
    window.Env[key] = env[key];
  });
}());
